.modal__box {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 350px;
    min-height: 200px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 24px;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: 70% 30%;
    padding-bottom: 20px;
}

.modal__titleBox {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 250px;
    max-width: 450px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 24px;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: unset !important;

    h4 {
        color: map-get($color-map, $key: primary);
        margin-bottom: 5px;
        text-align: center;
    }
}

.modal__formControl {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}
.modal__formControlGrid {
    display: grid !important;
    grid-template-columns: 11fr auto !important;
    width: 400px !important;
}
.modal__buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}

.modal__button {
    width: 50%;
    display: flex;
    justify-content: center;
}

.modal__message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem 2.5rem 0rem 2.5rem;
    font-size: 1.2rem;
    opacity: 0.8;
}

@media (max-width: 900px) {
    .modal__message {
        margin: 0.5rem 1.5rem 0rem 1.5rem;
        font-size: 1rem;
    }
}
