.unauthorized_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4rem;
    background-color: #fbfbfb;
    height: 100vh;

    .unauthorized_content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .unauthorized_title {
            font-size: 2.2rem;
            letter-spacing: 0.5px;
            font-weight: bold;
        }
        .unauthorized_subtitle1 {
            color: #999;
            font-size: 1rem;
            letter-spacing: 0.5px;
        }
    }
}
