@import '../../../../styles/colors.scss';

.logDisplay_button {
    margin-bottom: 10px;
}

.logDisplay_buttonBack {
    font-size: 1rem;
    letter-spacing: 0.2px;
}

.table_logDisplay_button {
    margin-right: 1rem !important;
}
