.configCourse__root {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.configCourse__tabs {
    max-width: 1100px;
    min-width: 900px;
    width: 100%;
}

.configCourse__tabContent {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
}

.configCourse__attrRoot {
    display: flex;
    justify-content: center;
}

.configCourse__attr {
    max-width: 1000px;
    min-width: 1000px;
    padding: 1.5rem 0.5rem;
}

.configCourse__attrForm {
    min-height: 250px;
    padding-top: 1.5rem;
    display: grid !important;
}

.configCourse__formLabel {
    margin: 0px !important;
    font-weight: 400 !important;
    font-size: 0.8rem !important;
}

.configCourse__switchGroup {
    padding-top: 1rem;
    align-items: center;
}

.configCourse__switchLabel {
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-line-clamp: 2;
    font-weight: 400 !important;
    font-size: 0.8rem !important;
}

.configCourse__textfieldLine {
    padding: 0.5em 0em 0.5em 0em !important;
    margin-bottom: 0px !important;
}
