@import '../../../styles/colors.scss';

// ! PRICES - FILTER
.filter__prices__tickets {
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 3.2fr 1.8fr; // ver el template ya que quité los botones
    padding: 1rem;
    .form_box {
        padding: 20px 0;
        max-width: 1100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .form__control {
            width: 90%;
        }
    }
    .filter__checkbox__edit_all {
        margin-left: 1.5rem;
        // border-left: 1px solid map-get($color-map, border);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

// ! PRICES - TABLE HEADER
.table__exchange_tickets_price_Header {
    display: grid !important;
    grid-template-columns: 15% 15% 20% 30% 19% 1%; // 1% al final para un gap
    background-color: map-get($color-map, primary) !important;
    align-items: center;
    // .tableCell__exchange_tickets_price_Header {
    //     display: grid;
    //     justify-content: center;
    // }
}

//! PRICES - TABLE ROW
.table__row_exchange_tickets_price {
    display: grid !important;
    grid-template-columns: 15% 15% 20% 30% 19% 1%;
    // padding-left: 1%;
}

// ! GRADUATION - TABLE HEADER
.table__exchange_tickets_graduation_Header {
    display: grid !important;
    grid-template-columns: 15% 15% 15% 15% 16% 16% 8%;
    background-color: map-get($color-map, primary) !important;
    align-items: center;
    // .tableCell__exchange_tickets_graduation_Header {
    //     display: grid;
    //     justify-content: center;
    // }
}
//! GRADUATION - TABLE ROW
.table__row_exchange_graduation_price {
    display: grid !important;
    grid-template-columns: 15% 15% 15% 15% 16% 16% 8%;
    // padding-left: 1%;
}

// ! HISTORICAL - FILTER FORM BOX
.form_container_historical_tickets {
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    .filter__control {
        .filter__body_exchange_tickets_line_historical_1 {
            grid-template-columns: 33% 33% 33%;
            padding: 15px 0;
        }
        .filter__body_exchange_tickets_line_historical_2 {
            grid-template-columns: 33% 33% 33%;
            padding: 15px 0;
        }
    }
}

// ! HISTORICAL - TABLE HEADER
.table__exchange_tickets_historical_Header {
    display: grid !important;
    grid-template-columns: 14% 13% 10% 12% 20% 8% 23%;
    background-color: map-get($color-map, primary) !important;
    align-items: center;
    .tableCell__exchange_tickets_historical_Header {
        // volado, este era el del checkbox
        display: grid;
        justify-content: center;
    }
}

//! HISTORICAL - TABLE ROW
.table__row_exchange_tickets_historical {
    display: grid !important;
    grid-template-columns: 14% 13% 10% 12% 20% 8% 23%;
    // padding-left: 1%;
}

.table__exchange_tickets_CellBody {
    //se usa en las 3 row de price, graduation y historical
    color: rgb(10, 10, 10) !important;
    display: grid !important;
    align-content: center !important;
    justify-content: center !important;
}
