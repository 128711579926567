.content_current_prices {
    margin-bottom: 30px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}

.formCourse__textfield {
    label {
        font-size: unset !important;
        color: #999;
        font-weight: unset;
        background-color: white;
    }
    .MuiInputBase-root {
        font-size: unset;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        fieldset {
            border: 1px solid #e0e0e0;
        }
    }
}

.formCourse__select {
    .MuiInputBase-root {
        height: 38px !important;
        background-color: white;
        input {
            padding: 0px 4px 7.5px 6px !important;
        }
    }
    .MuiAutocomplete-root {
        width: unset;
    }
    margin-bottom: 10px !important;
    label {
        font-size: unset !important;
        color: #999;
        font-weight: unset;
        padding-left: 4px;
        padding-right: 4px;
    }
    .MuiInputLabel-shrink {
        margin-top: 2px !important;
    }
    .MuiInputBase-root {
        font-size: unset;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        fieldset {
            border: 1px solid #e0e0e0;
        }
    }
}
.formCourse__matAutocomplete {
    .MuiInputBase-root {
        height: 38px !important;
        background-color: white;
        input {
            padding: 0px 4px 7.5px 6px !important;
        }
    }
    .MuiAutocomplete-root {
        width: unset;
    }
    // margin-bottom: 10px !important;
    label {
        font-size: unset !important;
        color: #999;
        font-weight: unset;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: -6px !important;
    }
    .MuiInputLabel-shrink {
        margin-top: 2px !important;
    }
    .MuiInputBase-root {
        font-size: unset;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        fieldset {
            border: 1px solid #e0e0e0;
        }
    }
}

.button-clean-filter {
    margin-top: 1rem;
    letter-spacing: 0px;
    color: #006c5b;
    opacity: 1;
    cursor: pointer;
}

.button-clean-filter :hover {
    background: #99ddd2 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}  
