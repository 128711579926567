.content_bulk_load {
    margin-bottom: 30px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}

.table_bulk_load {
    .table_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
}

.content_progress_bar {
    width: 100%;
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
    .items-space-between {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
    }
}
