@import '../../../styles/colors.scss';

.table__historical_Header {
    display: grid !important;
    grid-template-columns: 6% 8% 16% 6% 12% 10% 7% 7% 10% 13% 5%;
    // grid-template-columns: 8% 22% 6% 12% 10% 7% 7% 10% 13% 5%;
    background-color: map-get($color-map, primary) !important;
    align-items: center;
    .tableCell__historical_Header{
        display: grid;
        justify-content: center;
    }
}

.table__historicalBodyArticles {
    display: grid !important;
    grid-template-columns: 6% 8% 16% 6% 12% 10% 7% 7% 10% 13% 5%; // con checkbox
    // grid-template-columns: 8% 22% 6% 12% 10% 7% 7% 10% 13% 5%;
}

.table__HistoricalCellBody {
    color: rgb(10, 10, 10) !important;
    display: grid !important;
    align-content: center !important;
    justify-content: center !important;
    // text-transform: capitalize;
    // background-color: red !important;
}