@import '../../colors.scss';

.filter__prices {
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 1rem;
}

.filter__programs {
    max-width: 1100px;
    min-width: 400px;
    width: 100%;
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 1rem;
}
.filter__skills {
    width: 100%;
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 1rem;
}
.filter__bibliography {
    width: 100%;
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 1rem;
}
.filter__controls {
    align-items: center;
    display: grid;
}

.filter__line {
    display: grid;
    align-items: center;
    // margin-bottom: -10px;
}
.filter__line_modal {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.filter__line-211 {
    grid-template-columns: 2fr 1fr 1fr;
}

.filter__line-custom_prices_4 {
    grid-template-columns: 15% 35% 20% 30%;
}

.filter__line-custom_prices_5 {
    grid-template-columns: 22% 13% 20% 25% 20%;
}

.filter__line-custom_prices_3 {
    grid-template-columns: 40% 30% 30%;
}

.filter_line-custom_historical_line_4 {
    grid-template-columns: 21% 14% 20% 25% 20%;
}

.filter__line-12 {
    grid-template-columns: 2fr 2fr;
}

.filter__line-2 {
    grid-template-columns: 1fr 1fr;
}

.filter__line-3 {
    grid-template-columns: 28% 17% 25% 30%;
}

.filter__line-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.filter__line-3__custom {
    grid-template-columns: 1fr 1fr 1fr;
}

.filter__line112 {
    grid-template-columns: 1fr 1fr 2fr;
}

.filter__buttons {
    margin-left: 1rem;
    border-left: 1px solid map-get($color-map, border);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// for material-ui equals ng-ui-kit--------
.filter__textfield {
    width: 100%;
    // margin-bottom: 10px !important;
    label {
        font-size: unset !important;
        color: #999;
        font-weight: unset;
        background-color: white;
    }
    .MuiInputBase-root {
        font-size: unset;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        fieldset {
            border: 1px solid #e0e0e0;
        }

        input {
            // color: rgba(0, 0, 0, 0.2);
            color: #000;
        }
    }
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: unset;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #777 !important;
    border-width: 1px !important;
}
.css-nededu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #999 !important;
    font-size: unset !important;
    margin-bottom: 0px !important;
    margin-top: 4px;
}
.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #e9e9e9 !important;
}

.filter__titles {
    margin: 1% 0% 1% 0%;
}

.filter__massLoadValidation {
    border: 1px solid map-get($color-map, border);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 4fr 3fr 2fr;
    padding: 1rem;
}

.filter__line-custom_prices_3 .css-1xhypcz-MuiStack-root {
    padding-top: 4px !important;
}
// -------------------------------------

@media (max-width: 600px) {
    .filter__programs {
        display: flex;
        flex-direction: column;
    }
}
