@import '../../../styles/colors.scss';

.form_container {
    margin: 20px 0;
    max-width: 1100px;
    border: 1px solid #ccc;
    border-radius: 10px;
    // width: 100%;
    display: flex;
    flex-direction: column;

    .form_box {
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .formControl_careers {
            display: grid;
            align-items: center;
            width: 50%;
        }
    }
}

.Accordion_Head_billing_product_line_1 {
    // grid-template-columns: 90% 10%;
    width: 100%;
    // background-color: red ;
}

.filter__body_billing_product_line_1 {
    grid-template-columns: 30% 30% 40%;
    padding: 15px 0;
}
.filter__body_billing_product_line_2 {
    grid-template-columns: 27% 30% 20% 23%;
    padding: 15px 0;
    // text-transform: capitalize;
}

.formCourse__matAutocomplete {
    .MuiInputBase-root {
        height: 38px !important;
        background-color: white;
        input {
            padding: 0px 4px 7.5px 6px !important;
        }
    }
    .MuiAutocomplete-root {
        width: unset;
    }
}

// TODO:  TABLAS
.table__billing_product_Header {
    display: grid !important;
    grid-template-columns: 5% 15% 12% 11% 6% 22% 9% 6% 6% 4% 4%;
    background-color: map-get($color-map, primary) !important;
    align-items: center;
    .tableCell__historical_Header {
        display: grid;
        justify-content: center;
    }
}

.table__row_billing_product {
    display: grid !important;
    grid-template-columns: 5% 15% 12% 11% 6% 22% 9% 6% 6% 4% 4%;
    // padding-left: 1%;
}

.table__billing_product_CellBody {
    color: rgb(10, 10, 10) !important;
    display: grid !important;
    align-content: center !important;
    justify-content: center !important;
}
