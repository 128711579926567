@import '../../../styles/colors.scss';

.content_bulk-load-validation {
    margin-bottom: 10px;
    margin-top: 20px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}
.content_mass_validation_load {
    margin-bottom: 30px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}

.filter_bulk-load__line_4 {
    grid-template-columns: 12% 34% 26% 26%;
}

.table__bulkLoad_header {
    display: grid !important;
    grid-template-columns: 8% 22% 15% 15% 20% 13% 7%;
    background-color: map-get($color-map, primary) !important;
    margin-top: 1rem;
    & .table__massValidationSearchCell .table__courseCellRight {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.table__bulkLoad_Row_body {
    display: grid !important;
    grid-template-columns: 8% 22% 15% 15% 20% 13% 7%;
    & .table__massValidationSearchCell .table__courseCellRight {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}