.Navbar.Navbar__Container {
    z-index: 9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07) !important;
    padding: 0 8%;
    background-color: #fff;
}

.Navbar__Toolbar__Container {
    display: grid !important;
    grid-template-columns: 1fr 1fr 0.01fr 0.03fr 0.14fr !important;
    justify-items: center;
    white-space: nowrap;
}

.Navbar__link {
    display: flex;
    align-items: center;
}

.Navbar__AppName {
    justify-self: start;
    padding: 0.4rem;
    color: black;
}

a:hover {
    color: black;
    text-decoration: none;
}

.Navbar__AppName:hover {
    text-decoration: none;
}

.Navbar__User {
    justify-self: end;
    display: grid;
    justify-items: end;
}

.Navbar__Toolbar__Container > div {
    flex-grow: 1;
}

.Navbar__Logo span {
    font-size: 18px;
    font-weight: 700;
    padding-left: 0.5rem;
    vertical-align: top;
    margin-top: 10px;
    display: inline-block;
}

.Navbar__Menu {
    text-align: center;
}

.Navbar__User .UserMenu {
    position: absolute;
    top: 19px;
    right: 20px;
    display: flex;
}

.Navbar__User .UserMenu .UserMenu__Avatar {
    display: flex;
    color: var(--dark);
    margin: 3px 15px;
    font-weight: 500;
}

.Navbar__User .UserMenu .UserMenu__CauAvatar {
    display: inline-block;
    padding: 3px 10px;
    margin: auto;
    color: #006c5b;
    font-weight: 300;
    font-size: 0.7em;
    text-transform: uppercase;
    max-width: 200px;
}

.UserMenu__Menu__Item.UserMenu__Menu__Item__Logout {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;
    outline: none;
}
