.content_sale_tool {
    margin-bottom: 30px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}

.table_sale_tool {
    .table_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
}

.content_progress_bar {
    width: 100%;
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
    .items-space-between {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
    }
}

.tableIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.marginComponents {
    margin-top: 40px;
}

.styleModal {
    modal: {
        position: absolute;
        width: 400;
        background-color: white;
        border: 2px solid ·000;
        box-shadow: shadow(5);
        padding: 16px 32px 24px;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    textfield: {
        width: 100%;
    }
    containermodal: {
        text-align: center;
    }
}

$style: (
    position: absolute,
    top: 50%,
    left: 50%,
    transform: translate(-50%, -50%),
    width: 600px,
    border: 6px,
    boxShadow: 24,
    p: 2%
);

.element {
    position: map-get($style, position);
    top: map-get($style, top);
    left: map-get($style, left);
    transform: map-get($style, transform);
    width: map-get($style, width);
    border-radius: map-get($style, border);
    box-shadow: map-get($style, boxShadow);
    padding: map-get($style, p);
}

.formCourse__matAutocomplete_filter {
    flex: 1;
    .MuiInputBase-root {
        height: 38px !important;
        background-color: white;
        input {
            padding: 0px 4px 7.5px 6px !important;
        }
    }
    .align-right {
        display: flex;
        justify-content: flex-end;
    }

    .MuiAutocomplete-root {
        width: unset;
    }

    label {
        font-size: unset !important;
        color: #999;
        font-weight: unset;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: -6px !important;
    }
    .MuiInputLabel-shrink {
        margin-top: 2px !important;
    }
    .MuiInputBase-root {
        font-size: unset;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        fieldset {
            border: 1px solid #e0e0e0;
        }
    }
}

.new_Display_Button {
    justify-content: flex-end;
    margin-top: 10px;
}

.new_Display_TypeStudent_options {
    font-size: 0.4rem !important;
    margin-left: 10px;
}

.new_Display_TypeStudent {
    font-size: 1rem;
    letter-spacing: 0.2px;
    margin-left: 10px;
    margin-top: 10px;
}

.content_sale_tool {
    margin-bottom: 30px;
    .module_title {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    .module_subtitle1 {
        color: #999;
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
}

.new_log__line {
    align-items: center;
    display: grid;
    margin-top: 15px;
}

.custom-autocomplete-container {
    max-width: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.modal_period {
    margin-top: 0.25rem !important;
}
